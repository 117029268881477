import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c(VSheet,{staticClass:"mx-auto",attrs:{"border":"solid","width":"300"}},[_c('img',{attrs:{"src":_vm.qrCode}}),(_vm.qrCode!=='')?_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VCardText,[_c(VLabel,[_vm._v(" Scan the QR Code into Google Authenticator App and enter the code. ")]),_c(VTextField,{attrs:{"label":"Auth Code","required":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c(VBtn,{staticClass:"mt-2",attrs:{"type":"submit","block":""},on:{"click":function($event){return _vm.Submit()}}},[_vm._v(" Enter ")])],1):_vm._e()],1)],1),_c('div',[_c(VSheet,{staticClass:"mx-auto",attrs:{"width":"300"}},[(_vm.qrCode==='')?_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VCardText,[_c(VLabel,[_vm._v(" Enter your login email and choose your site ")]),_c(VTextField,{attrs:{"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('site-select',{attrs:{"site":_vm.site},on:{"site-select":_vm.updateSite}})],1),_c(VBtn,{staticClass:"mt-2",attrs:{"type":"submit","block":""},on:{"click":function($event){return _vm.Update()}}},[_vm._v(" Begin ")])],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }